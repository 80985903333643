import Table from 'react-bootstrap/Table';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';

// Configuración adicional para mostrar la representación relativa de tiempo en español
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime, {
  future: 'en %s',
  past: 'hace %s',
  s: 'unos segundos',
  m: 'un minuto',
  mm: '%d minutos',
  h: 'una hora',
  hh: '%d horas',
  d: 'un día',
  dd: '%d días',
  M: 'un mes',
  MM: '%d meses',
  y: 'un año',
  yy: '%d años',
});
dayjs.locale('es');

const Version = () => {
  const [lastModified, setlastModified] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/champs`);
     
        if (!response.ok) {
          const errorText = await response.text();
          console.error('Error en la respuesta del servidor:', response.status, errorText);
          throw new Error('Error en la respuesta del servidor');
        }
     
        const data = await response.json();
        setlastModified(data.length > 0 ? dayjs.utc(data[0].lastUpdated) : null);
     
      } catch (error) {
        console.error('Error al obtener datos del servidor:', error);
      }
    };

    fetchData();
  }, []);

  const renderTooltip = (props) => (
    <Tooltip {...props} id="tooltip">
      {lastModified !== null && (
        <span>
          Última Actualización: {dayjs.utc(lastModified).fromNow()} ({dayjs.utc(lastModified).format('DD/MM/YYYY HH:mm')})
        </span>
      )}
    </Tooltip>
  );

  const renderUpdateTime = () => {
    if (lastModified !== null) {
      const formattedTime = dayjs.utc(lastModified).fromNow();
      return <span>Última Actualización: {formattedTime}</span>;
    } else {
      return <span>Cargando...</span>;
    }
  };

  return (
    <section style={{ maxWidth: '340px' }}>
      <Table className=''  hover>
        <thead>
          <tr >
            <th className='version-tag nav-link-champs'>{`Parche: 6.0`}</th>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <th className='version-tag nav-link-champs'>
                {renderUpdateTime()}
              </th>
            </OverlayTrigger>
          </tr>
        </thead>
      </Table>
    </section>
  );
};

export default Version;
